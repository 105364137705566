// NiveisTEA.js
import React, { useState } from 'react';
import Header from '../../components/siderbar';
import './styles.css';
import nivel from "../../assets/nivel-tea2.png";
import Modal from '../../components/modal';
import { useTranslation } from 'react-i18next';


import InfoCard from '../../components/infoCard/InfoCard';
import questionIcon from '../../assets/nivel-tea.png'




function NiveisTEA() {
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});

    const niveisData = {
        nivel1: {
            titulo: t("nivel1_title"),
            texto: t("nivel1_text"),
            subtitulo: t("restricted_behaviors"),
            subtexto: t("nivel1_subtext"),
            cor: "baixo-risco"
        },
        nivel2: {
            titulo: t("nivel2_title"),
            texto: t("nivel2_text"),
            subtitulo: t("restricted_behaviors"),
            subtexto: t("nivel2_subtext"),
            cor: "moderado-risco"
        },
        nivel3: {
            titulo: t("nivel3_title"),
            texto: t("nivel3_text"),
            subtitulo: t("restricted_behaviors"),
            subtexto: t("nivel3_subtext"),
            cor: "alto-risco"
        }
    };

    const openModal = (nivel) => {
        setModalContent(niveisData[nivel]);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <Header>
      <InfoCard title={t("tea_levels")} icon={questionIcon} />
            <div className="tutorial-container">
                <div className="tutorial-card">
                    <div className="tutorial-header">
                        <img src={nivel} alt={t("process_icon_alt")} className="sidebar-icon-2" />
                        <h2>{t("tea_levels_title")}</h2>
                    </div>
                    <p className="tutorial-text">{t("tea_description_1")}</p>
                    <p className="tutorial-text">{t("tea_description_2")}</p>
                    <p className="tutorial-text">{t("tea_description_3")}</p>
                    <p className="tutorial-text">{t("tea_description_4")}</p>
                    <p className="tutorial-text">{t("tea_description_5")}</p>
                </div>

                <div className="niveisTEA">
                    <h3 className="niveis-instrucao">{t("levels_instruction")}</h3>
                    <div className="niveis-info-tea">
                        <div className="nivel nivel1" onClick={() => openModal('nivel1')}>
                            <span>{t("level1")}</span>
                            <span className='span-level'>{t("level1-span")}</span>
                        </div>
                        <div className="nivel nivel2" onClick={() => openModal('nivel2')}>
                            <span>{t("level2")}</span>
                            <span className='span-level'>{t("level2-span")}</span>
                        </div>
                        <div className="nivel nivel3" onClick={() => openModal('nivel3')}>
                            <span>{t("level3")}</span>
                            <span className='span-level'>{t("level3-span")}</span>
                        </div>
                    </div>
                </div>
            </div>

         

            {modalOpen && (
                <Modal
                    titulo={modalContent.titulo}
                    texto={modalContent.texto}
                    subtitulo={modalContent.subtitulo}
                    subtexto={modalContent.subtexto}
                    cor={modalContent.cor}
                    onClose={closeModal}
                />
            )}
        </Header>
    );
}

export default NiveisTEA;
