import React, { useEffect, useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import Logo from "../../assets/logo.png";
import mchatrf from "../../assets/m-chat-rf.png";
import home from "../../assets/home.png";
import tutorial from "../../assets/tutorial.png";
import processo from "../../assets/processo.png";
import nivel from "../../assets/nivel-tea.png";
import duvidas from "../../assets/duvidas.png";
import info from "../../assets/info.png";
import exit from "../../assets/exit.png";
import { getAuthHeaders } from '../../services/authHeaders';
import { logout } from '../../services/logout';
import './styles.css';

function Header({ children }) {
    const { t } = useTranslation();
    const [userName, setUserName] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    // Verificações para exibir o conteúdo com base na rota e no dispositivo
    const isHomePage = location.pathname === '/home-page';
    const isMobile = window.innerWidth <= 768;
    const isWeb = window.innerWidth > 768;

    useEffect(() => {
        const fetchUserName = async () => {
            const userId = localStorage.getItem('userId');
            try {
                const response = await fetch(`https://apiprojetotea.squareweb.app/usuario/${userId}`, {
                    method: 'GET',
                    headers: getAuthHeaders(),
                });
                if (response.ok) {
                    const data = await response.json();
                    setUserName(getFirstAndSecondName(data.usuario.nome_completo));
                } else {
                    console.error(t('user_data_error'));
                }
            } catch (error) {
                console.error(t('server_connection_error'), error);
            }
        };

        fetchUserName();
    }, [t]);

    const getFirstAndSecondName = (fullName) => {
        const nameParts = fullName.split(' ');
        return nameParts.length > 1 ? `${nameParts[0]} ${nameParts[1]}` : nameParts[0];
    };

    const handleLinkClick = (event, path) => {
        if (location.pathname === '/cadastro-m-chat-r-f') {
            event.preventDefault();
            Swal.fire({
                title: t('confirm_exit_title'),
                text: t('confirm_exit_text'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t('yes_exit'),
                cancelButtonText: t('no_continue'),
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(path);
                }
            });
        }
    };

    const handleLogout = () => {
        Swal.fire({
            title: t('confirm_logout_title'),
            text: t('confirm_logout_text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('yes'),
            cancelButtonText: t('no'),
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                logout();
                navigate('/login');
                Swal.fire(
                    t('logged_out_title'),
                    t('logged_out_text'),
                    'success'
                );
            }
        });
    };

    return (
        <div className="layout">
            {/* Exibir header sempre na web ou apenas na home-page no mobile */}
            {(isWeb || (isMobile && isHomePage)) && (
                <header className="header">
                    <div className="header-content">
                        <div className="header-left">
                            <img src={Logo} alt={t("logo_alt_text")} className="header-logo" />
                            <h1 className="header-title">{t("institute_name")}</h1>
                        </div>
                        <div className="header-right">
                            <div>
                                <span className="header-greeting">{t("hello")}, {userName}</span>
                                <p className='isMobi welcome'>
                                    Acesse os serviços disponíveis.
                                </p>
                            </div>
                            <Link to="/perfil-user" onClick={(e) => handleLinkClick(e, '/perfil-user')}>
                                <FaUserCircle className="user-icon" />
                            </Link>
                        </div>
                    </div>
                </header>
            )}

            {/* Exibir menuMobile apenas em /home-page e mobile */}

            <div className='menuMobile'>
                {/* Sidebar */}
                {(isWeb || (isMobile && isHomePage)) && (
                    <aside className="sidebar">
                        <nav className="sidebar-nav">
                            <Link to="/home-page" className='isMobiSome' onClick={(e) => handleLinkClick(e, '/home-page')}>
                                <div>
                                    <img src={home} alt="Home Icon" className="sidebar-icon" />
                                </div>
                                <div>
                                    <p>{t("home")}</p>
                                </div>
                            </Link>
                            <Link to="/tutorial-m-chat-rf" onClick={(e) => handleLinkClick(e, '/tutorial-m-chat-rf')}>
                                <div className='contentBGMobi'>
                                    <img src={tutorial} alt="Tutorial Icon" className="sidebar-icon" />
                                </div>
                                <div className='infoText-mob'>
                                    <p>{t("tutorial")}</p>
                                </div>
                            </Link>
                            
                            <Link to="/cadastro-m-chat-r-f" onClick={(e) => handleLinkClick(e, '/cadastro-m-chat-r-f')}>
                                <div className='contentBGMobi'>
                                    <img src={mchatrf} alt="M-CHAT-R/F Icon" className="sidebar-icon" />
                                </div>
                                <div className='infoText-mob'>
                                    <p>M-CHAT-R/F</p>
                                </div>
                            </Link>
                           
                            <Link to="/processo-triagem" onClick={(e) => handleLinkClick(e, '/processo-triagem')}>
                                <div className='contentBGMobi'>
                                    <img src={processo} alt="Processo Icon" className="sidebar-icon" />
                                </div>
                                <div className='infoText-mob'>
                                    <p>{t("screening_process")}</p>
                                </div>
                            </Link>
                            <Link to="/niveis-tea" onClick={(e) => handleLinkClick(e, '/niveis-tea')}>
                                <div className='contentBGMobi'>
                                    <img src={nivel} alt="Níveis do TEA Icon" className="sidebar-icon" />
                                </div>
                                <div className='infoText-mob'>
                                    <p>{t("tea_levels")}</p>
                                </div>
                            </Link>
                            <Link to="/duvidas-sugestoes" onClick={(e) => handleLinkClick(e, '/duvidas-sugestoes')}>
                                <div className='contentBGMobi'>
                                    <img src={duvidas} alt="Dúvidas Icon" className="sidebar-icon" />
                                </div>
                                <div className='infoText-mob'>
                                    <p>{t("questions_suggestions")}</p>
                                </div>
                            </Link>
                            <Link to="/mais-informacoes" onClick={(e) => handleLinkClick(e, '/mais-informacoes')}>
                                <div className='contentBGMobi'>
                                    <img src={info} alt="Informações Icon" className="sidebar-icon" />
                                </div>
                                <div className='infoText-mob'>
                                    <p>{t("more_info")}</p>
                                </div>
                            </Link>
                            <Link onClick={handleLogout} className="logout-button isMobiSome">
                                <img src={exit} alt="Logout Icon" className="sidebar-icon" />
                                <div>{t("logout")}</div>
                            </Link>
                        </nav>
                    </aside>
                )}
                {/* Main content area */}

                {isMobile && (
                    <main className="main-content">
                        {children}
                    </main>
                )}
            </div>


            {/* Exibir main-content para web em qualquer página */}

            <main className="main-content">
                {children}
            </main>

        </div>
    );
}

export default Header;
