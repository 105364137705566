// Login.js
import React, { useState } from 'react';
import './styles.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

function Login() {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://apiprojetotea.squareweb.app/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, senha })
            });

            const data = await response.json();

            if (response.ok) {
                // Armazenar o token e o ID do usuário no localStorage
                localStorage.setItem('token', data.token);
                localStorage.setItem('userId', data.userId); // Armazena o ID do usuário

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: t('login_success'),
                    showConfirmButton: false,
                    timer: 1500
                });

                // Redirecionar para a página protegida (HomePage) após o login
                navigate('/home-page');
            } else {
                Swal.fire({
                    icon: 'error',
                    title: t('error'),
                    text: data.erro || t('login_error')
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: t('error'),
                text: t('connection_error')
            });
        }
    };

    return (
        <div className="login-container">

            <div className='contetn-info-texts'>
                <h1 className="welcome-title">{t("welcome")}</h1>
                <p className="subtitle">{t("credentials_prompt")}</p>
            </div>

            <form className="login-form" onSubmit={handleLogin}>
                <div className="password-field">
                    <input
                        placeholder={t("email")}
                        type="email"
                        className="input-field"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <span onClick={togglePasswordVisibility} className="toggle-password-none">
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                </div>
                <div className="password-field">
                    <input
                        type={showPassword ? "text" : "password"}
                        className="input-field"
                        placeholder={t("password")}
                        value={senha}
                        onChange={(e) => setSenha(e.target.value)}
                    />
                    <span onClick={togglePasswordVisibility} className="toggle-password">
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                </div>

                <div className="options">
                    <label>
                        <input type="checkbox" />
                        {t("keep_connected")}
                    </label>
                    <Link to="/recuperando-senha" className="forgot-password">{t("forgot_password")}</Link>
                </div>

                <button type="submit" className="login-button">{t("login")}</button>

                <p className="register-prompt">
                    {t("no_account")} <Link to="/cadastro" className="forgot-password">{t("create_account_title")}</Link>
                </p>
            </form>
        </div>
    );
}

export default Login;
