import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../Translate/en.json';
import pt_br from '../Translate/pt_br.json';
import pt_pt from '../Translate/pt_pt.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            pt_br: { translation: pt_br },
            en: { translation: en },
            pt_pt: { translation: pt_pt },
        },
        lng: 'pt_br', // Idioma padrão
        fallbackLng: 'pt_br',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
