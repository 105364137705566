import React, { useState, useEffect } from 'react';
import Header from '../../components/siderbar';
import './styles.css';
import { FaArrowRight } from 'react-icons/fa';
import PerguntasMchatRf from '../../components/mchatrf/PerguntasMchatRf';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import InfoCard from '../../components/infoCard/InfoCard';
import questionIcon from '../../assets/m-chat-rf.png';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import i18n from '../../services/i18n';

function CadastroMchatRf() {
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(false);
    const [showPerguntas, setShowPerguntas] = useState(false);
    const [formData, setFormData] = useState({
        nomeCrianca: '',
        dataNascimento: '',
        responsavel: '',
        numeroContato: '',
        endereco: '',
        cidade: ''
    });

    // Detectar se é mobile
    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth <= 768); // Define mobile para telas menores ou iguais a 768px
        };
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);

        return () => {
            window.removeEventListener('resize', checkIfMobile);
        };
    }, []);

    const handleContinue = () => {
        if (Object.values(formData).some(field => field.trim() === '')) {
            Swal.fire({
                icon: 'warning',
                title: t('required_fields_title'),
                text: t('required_fields_text'),
                confirmButtonText: 'OK',
                confirmButtonColor: '#4a63d0'
            });
        } else {
            setShowPerguntas(true);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Atualizar data selecionada no DatePicker
    const handleDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            dataNascimento: date
        }));
    };

    return (
        <Header>
            <InfoCard title="M-CHAT-R/F" icon={questionIcon} />
            {!showPerguntas ? (
                <>
                    <div className="mchat-container">
                        <div className="mchat-header">
                            <p>{t('mchat_description')}</p>
                        </div>

                        <div className="mchat-form">
                            <input
                                type="text"
                                name="nomeCrianca"
                                placeholder={t("child_name")}
                                onChange={handleChange}
                            />

                            {/* Condição para mostrar o campo de data */}
                            {isMobile ? (
                                <ReactDatePicker
                                    selected={formData.dataNascimento}
                                    onChange={handleDateChange}
                                    placeholderText={t("dob_placeholder")}
                                    dateFormat={i18n.language === "en" ? "MM/dd/yyyy" : "dd/MM/yyyy"} // Verifica o idioma atual
                                    className="date-picker-input"
                                />
                            ) : (
                                <input
                                    type="date"
                                    name="dataNascimento"
                                    placeholder={t("dob_placeholder")}
                                    onChange={handleChange}
                                />
                            )}

                            <input
                                type="text"
                                name="responsavel"
                                placeholder={t("responsible")}
                                onChange={handleChange}
                            />
                            <input
                                type="tel"
                                name="numeroContato"
                                placeholder={t("contact_number")}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="endereco"
                                placeholder={t("address")}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="cidade"
                                placeholder={t("city")}
                                onChange={handleChange}
                            />
                        </div>

                        <div className='button-org-info-cadastro-m-chat'>
                            <button className="continue-btn" onClick={handleContinue}>
                                {t("continue")} <FaArrowRight />
                            </button>


                        </div>
                    </div>
                </>
            ) : (
                <PerguntasMchatRf formData={formData} />
            )}
        </Header>
    );
}

export default CadastroMchatRf;
