import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSelector.css';

function LanguageSelector() {
    const { i18n } = useTranslation();
    const [flag, setFlag] = useState('https://flagicons.lipis.dev/flags/4x3/br.svg'); // Bandeira inicial
    const [selectedLanguage, setSelectedLanguage] = useState(''); // Inicia com uma string vazia

    // Define a função `changeLanguage` usando `useCallback` para garantir que ela não seja recriada
    const changeLanguage = useCallback((language) => {
        if (language) { // Verifica se algum idioma foi selecionado
            i18n.changeLanguage(language);
            setSelectedLanguage(language);
            localStorage.setItem('language', language); // Salva o idioma selecionado no localStorage

            // Define a bandeira com base no idioma selecionado
            switch (language) {
                case 'pt_br':
                    setFlag('https://flagicons.lipis.dev/flags/4x3/br.svg');
                    break;
                case 'pt_pt':
                    setFlag('https://flagicons.lipis.dev/flags/4x3/pt.svg');
                    break;
                case 'en':
                    setFlag('https://flagicons.lipis.dev/flags/4x3/us.svg');
                    break;
                default:
                    setFlag('https://flagicons.lipis.dev/flags/4x3/us.svg');
                    break;
            }
        }
    }, [i18n]);

    // Verifica e define o idioma salvo no localStorage ao carregar o componente
    useEffect(() => {
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage) {
            setSelectedLanguage(savedLanguage);
            changeLanguage(savedLanguage); // Define a bandeira e o idioma inicial
        }
    }, [changeLanguage]);

    return (
        <div className="language-selector">
            <img src={flag} alt="Language flag" className="flag-icon" />
            <select
                value={selectedLanguage} // Define o valor inicial do <select>
                onChange={(e) => changeLanguage(e.target.value)}
                className="language-dropdown"
            >
                <option value="pt_br">Português (Brasil)</option>
                <option value="pt_pt">Português (Portugal)</option>
                <option value="en">English</option>
            </select>
        </div>
    );
}

export default LanguageSelector;
